import { createCrudActions } from '../utils/crudGenerator';

const formatCustomers = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchCustomers,
    create: createCustomer,
    update: updateCustomer,
    delete: deleteCustomers
} = createCrudActions('CUSTOMER', "Cliente", formatCustomers);