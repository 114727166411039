import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  customers,
  customersForm,
  visits,
  visitsForm,
} from "./Reducers";

export default combineReducers({
  visits,
  visitsForm,
  customers,
  customersForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
