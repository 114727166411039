import moment from "moment";
import { createCrudActions } from '../utils/crudGenerator';

const formatVisits = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
        date: item.date && moment(item.date),
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchVisits,
    create: createVisit,
    update: updateVisit,
    delete: deleteVisits
} = createCrudActions('VISIT', "Visita", formatVisits);
